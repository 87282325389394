import React, { Component} from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CompetitionBlock from "../components/MainStats/CompetitionBlock"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import CopyBlock from "../components/Copy/CopyBlock"

import gamesPerStat from "../js/gamesPerStat"
import percentageOf from "../js/percentageOf"

import aviatorGame from "../images/ads/aviator-game.jpg"
import melbetApp from "../images/ads/melbet-app.png"

function IndexPage(props) {

  let [{
    apps: mAllTimeApps,
    goals: mAllTimeGoals,
    assists: mAllTimeAssists,
    goalsPerGame: mAllTimeGoalsPerGame,
    minsPerGoal: mAllTimeMinsPerGoal,
    minsPerGoalContribution: mAllTimeMinsPerGoalCont,
    keyPasses: mAllTimeKeyPasses,
    throughballs: mAllTimeThroughballs,
    successfulDribbles: mAllTimeDribbles,
    aerialDuels: mAllTimeAerialDuels,
    shots: mAllTimeShots,
    shotsOnTarget: mAllTimeShotsOnTarget,
    shotsPerGoal: mAllTimeShotsPerGoal,
    head: mAllTimeHead,
    pens: mAllTimePens,
    pensMissed: mAllTimePensMissed,
    freeKicks: mAllTimeFreeKicks,
    hatTricks: mAllTimeHatTricks
  }] = props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time Career").map(d => d.node)

  let [{
    apps: rAllTimeApps,
    goals: rAllTimeGoals,
    assists: rAllTimeAssists,
    goalsPerGame: rAllTimeGoalsPerGame,
    minsPerGoal: rAllTimeMinsPerGoal,
    minsPerGoalContribution: rAllTimeMinsPerGoalCont,
    keyPasses: rAllTimeKeyPasses,
    throughballs: rAllTimeThroughballs,
    successfulDribbles: rAllTimeDribbles,
    aerialDuels: rAllTimeAerialDuels,
    shots: rAllTimeShots,
    shotsOnTarget: rAllTimeShotsOnTarget,
    shotsPerGoal: rAllTimeShotsPerGoal,
    head: rAllTimeHead,
    pens: rAllTimePens,
    pensMissed: rAllTimePensMissed,
    freeKicks: rAllTimeFreeKicks,
    hatTricks: rAllTimeHatTricks
  }] = props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time Career").map(d => d.node)

  let [{
    apps: mChampsApps,
    goals: mChampsGoals,
    assists: mChampsAssists,
    hatTricks: mChampsHatTricks
  }] = props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time Champions League").map(d => d.node)

  let [{
    apps: rChampsApps,
    goals: rChampsGoals,
    assists: rChampsAssists,
    hatTricks: rChampsHatTricks
  }] = props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time Champions League").map(d => d.node)

  let [{
    goals: mIntGoals,
    apps: mIntApps,
    minsPerGoal: mIntMinsPerGoal,
    minsPerGoalContribution: mIntMinsPerGoalCont,
  }] = props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time Internationals").map(d => d.node)

  let [{
    goals: rIntGoals,
    apps: rIntApps,
    minsPerGoal: rIntMinsPerGoal,
    minsPerGoalContribution: rIntMinsPerGoalCont,
  }] = props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time Internationals").map(d => d.node)

  const mAppsSince09 = props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "League and Europe Since 09").map(d => d.node.apps)
  const rAppsSince09 = props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "League and Europe Since 09").map(d => d.node.apps)

  const mLeagueHatTricks = props.data.allSheetMessiAllTimeStats.edges.filter(d => d.node.competition === "All Time League").map(d => d.node.hatTricks)
  const rLeagueHatTricks = props.data.allSheetRonaldoAllTimeStats.edges.filter(d => d.node.competition === "All Time League").map(d => d.node.hatTricks)

  return (
    <Layout>
      <SEO
        title={"Messi vs Ronaldo - All Time Career Goals and Stats"}
        description={`Compare Messi vs Ronaldo all time career stats, including all time goals, assists, free kicks, hat-tricks and scoring ratios!`}
        canonicalPath={`/`}
      />

      <h1><span className="sr-only">Messi vs Ronaldo</span> All Time Stats</h1>
      

      <CompetitionBlock competition="All Time Career" subtitle="Excluding club friendlies" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} perfmsg="[comps] since [season]" />

      <CompetitionBlock competition="All Time Career Europe" compTitle="All Time Career Without USA/Saudi" modalTitle="All Time Career w/o USA/Saudi" subtitle="International Stats + European Club Stats" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} perfmsg="[comps] since [season]" />

      <CompetitionBlock competition="All Time Club" subtitle="Excluding friendlies" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} perfmsg="[comps] since [season]" />

      <CompetitionBlock competition="All Time Club Europe" compTitle="All Time Club Europe" modalTitle="All Time Club w/o USA/Saudi" subtitle="All club stats for European clubs (excluding friendlies)" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} perfmsg="[comps] since [season]" />

      <CompetitionBlock competition="All Time League" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} perfmsg="Since [season] only" />

      <CompetitionBlock competition="All Time League Europe" subtitle="League stats in all European Leagues" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} perfmsg="Since [season] only" />

      <CompetitionBlock competition="All Time Top 5 Leagues" subtitle="League stats in Europe's Top 5 Leagues" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} perfmsg="Since [season] only" />

      <CompetitionBlock competition="UCL Groups + Knockouts" compTitle="All Time UEFA Champions League" subtitle="Excluding qualifying" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} perfmsg="Since [season] only" />

      <CompetitionBlock competition="UCL Qualifiers" compTitle="All Time UEFA Champions League Qualifying" modalTitle="UCL Qualifying" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} perfmsg="Since [season] only" />

      <CompetitionBlock competition="All Time Domestic Cups" subtitle="Copa del Rey, Coppa Italia, FA Cup, EFL Cup, Coupe de France, Saudi King Cup, MLS Cup, US Open Cup" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} />

      <CompetitionBlock competition="All Time Domestic Super Cup" subtitle="Supercopa de Espana, Supercoppa Italiana, FA Community Shield, Trophée des Champions, Saudi Super Cup, Campeones Cup" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} />

      <CompetitionBlock competition="All Time UEFA Super Cup" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} />

      <CompetitionBlock competition="All Time Club World Cup" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} />

      <CompetitionBlock competition="All Time UEFA Cup / Europa League" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} />

      <CompetitionBlock competition="All Time Internationals" type="all" mdata={props.data.allSheetMessiAllTimeStats.edges} rdata={props.data.allSheetRonaldoAllTimeStats.edges} />

      <ul className="text-center mt-12">
        <li><a className="underline hover:text-highlight py-1 inline-block" rel="noreferrer" target="_blank" href="https://pl.polskiekasynohex.org/">Polskie KasynoHEX</a> - Casino Ranking and Reliable Reviews</li>
        <li>Enjoy online gambling for less with a <a className="underline hover:text-highlight py-1 inline-block" rel="noreferrer" target="_blank" href="https://casinoofthekings.ca/low-deposit-casino/5-deposit/">$5 minimum deposit casino</a>.</li>
        <li><a className="underline hover:text-highlight py-1 inline-block" rel="noreferrer" target="_blank" href="https://1wintg.com/">1win</a> Togo is legal sports betting and online casino platform.</li>
        <li>Reviews of the best <a className="underline hover:text-highlight py-1 inline-block" rel="noreferrer" target="_blank" href="https://online-casinoau.com/">online casino Australia</a> sites based on real experiences</li>
        <li><a className="underline hover:text-highlight py-1 inline-block" rel="noreferrer" target="_blank" href="https://promuscle.club/">ProMuscle.club</a></li>
        <li><a className="underline hover:text-highlight py-1 inline-block" rel="noreferrer" target="_blank" href="https://www.completesports.com/10-most-trusted-casinos-not-on-gamstop-uk">Sites Not On GamStop</a></li>
        <li><a className="underline hover:text-highlight py-1 inline-block" rel="noreferrer" target="_blank" href="https://leadership.ng/casinos-not-on-gamstop-list-of-non-gamstop-sites/">Slots Not On GamStop</a></li>
      </ul>

      <div className="w-full max-w-screen-md mx-auto text-center flex justify-center items-center mt-6">
        <a href="https://aviator.com.in/" className="mr-4" rel="noreferrer" target="_blank"><img src={aviatorGame} alt="Aviator Game" width="250" /></a>
        <a href="https://melbets.bet/app/" className="bg-white" rel="noreferrer" target="_blank"><img src={melbetApp} alt="Melbet App" width="250" /></a>
      </div>

      <CopyBlock id="whos-the-best" classes="mt-16">
          <h2>Messi vs Ronaldo - so who is the best?</h2>
          <p><strong className="text-highlight">Lionel Messi</strong> and <strong className="text-highlight">Cristiano Ronaldo</strong> are two of the best footballers of all time, arguably <em>the</em> very best of them all. And remarkably, their careers have largely coincided, with both players dominating the game for well over a decade now. But who is the best?</p>
          <p>We compare them both in a number of different categories and let you decide the overall winner.</p>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who scores more goals?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>What really made this rivalry catch fire was their incredible, unprecedented goalscoring rates, with both Messi and Ronaldo averaging more than one goal every game at their peaks. During the 9 years Ronaldo spent at Real Madrid, <strong className="text-highlight">Messi and Ronaldo both scored a goal every 85 minutes</strong> in all competitions. The adjacency of their goalscoring - matching each other blow for blow - has been astounding over the years.</p>

            <p>Over their entire careers, it's Messi who edges the ratio, with <strong className="text-messi">{parseFloat(mAllTimeGoalsPerGame).toFixed(2)} goals per game</strong>, whereas Ronaldo has scored <strong className="text-ronaldo">{parseFloat(rAllTimeGoalsPerGame).toFixed(2)} goals per game</strong>. If we drill down into goals per minutes, Messi <strong className="text-messi">scores a goal every {Math.round(mAllTimeMinsPerGoal)} minutes</strong>, and Ronaldo <strong className="text-ronaldo">scores a goal every {Math.round(rAllTimeMinsPerGoal)} minutes</strong>.</p>

            <p>Ronaldo still has more <strong className="text-highlight">all time career goals</strong> than Messi though (Ronaldo has <strong className="text-ronaldo">{rAllTimeGoals} career goals</strong> and Messi has <strong className="text-messi">{mAllTimeGoals} career goals</strong>), but Ronaldo has played {rAllTimeApps-mAllTimeApps} more games over his career than Messi has.</p>

            <p>Although Messi shades the overall ratio, Ronaldo spent more of his formative years as a more traditional right winger, only really starting to focus on goalscoring as his primary function in 2006, 3-4 years into his career. For Messi, it was 2-3 years until he completed the transformation from a nippy winger into a player hungry for goals above everything else. If we take these differing beginnings into account, the ratios would be even closer.</p>

            <p>Of all the categories, goalscoring is the hardest to separate them on.</p>

            {/* <p className="pt-4">
              <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
              <strong className="uppercase text-highlight py-1 px-2 border border-gray-700 border-l-0">Tie</strong>
            </p> */}
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who provides more assists?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>When it comes to assists, there's only one winner. Lionel Messi's playmaking abilities are right up there with the very best, and he has the numbers to prove it.</p>

            <p>Messi has racked up an impressive <strong className="text-messi">{mAllTimeAssists} assists in {mAllTimeApps} career appearances</strong>, compared to Ronaldo's <strong className="text-ronaldo">{rAllTimeAssists} assists in {rAllTimeApps} games</strong>.</p>

            <p>However, because Messi has a clear ascendancy when it comes to assists, this often leads people to fall into the trap of underestimating Ronaldo's proficiency in this area. Ronaldo's assist numbers are still extremely impressive compared to the other mere mortals that play the beautiful game.</p>

            <p>In fact, Ronaldo has more in the  <strong className="text-highlight">Champions League</strong>, with <strong className="text-ronaldo">{rChampsAssists} assists</strong> to Messi's <strong className="text-messi">{mChampsAssists} assists</strong> - granted Ronaldo has played {rChampsApps-mChampsApps} more matches than Messi, but this is still an impressive tally.</p>

            <p>With that said, you can't ignore Messi's overall assist stats.</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="py-1 px-2 text-noir uppercase tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-messi py-1 px-2 border border-gray-700 border-l-0">Messi</strong>
              </p> */}

              <Link to={`/detailed-stats/opta-assists/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">More Assist Stats</Link>
            </div>
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who's the best at passing?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>The passing stats point to a clear winner in this category; Lionel Messi. But again, Ronaldo's passing abilities are often underestimated.</p>

            <p>In the <strong className="text-highlight">league and Champions League since 2009/10</strong>, Messi has made <strong className="text-messi">{mAllTimeKeyPasses} key passes in {mAppsSince09} appearances</strong>, whilst Ronaldo has made <strong className="text-ronaldo">{rAllTimeKeyPasses} key passes in {rAppsSince09} appearances</strong>.</p>
            
            <p>Messi also dominates the <strong className="text-highlight">throughball</strong> statistics, making <strong className="text-messi">{mAllTimeThroughballs} successful throughballs</strong> in that same time period, compared to Ronaldo's <strong className="text-ronaldo">{rAllTimeThroughballs} successful throughballs</strong>.</p>

            <p>Although Messi's passing numbers are clearly superior, Ronaldo's are still impressive. It's also likely that his passing stats would have been significantly higher before 2009 when he was more involved in the creative build-up play.</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-messi py-1 px-2 border border-gray-700 border-l-0">Messi</strong>
              </p> */}

              <Link to={`/detailed-stats/key-passes/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">More Key Pass Stats</Link>
            </div>
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who's the best at dribbling?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>Ronaldo started his career as a tricky winger, when his game was all about running at and beating defenders with his pace and skill. As he has transitioned to an inside-forward / striker role over the years, dribbling has naturally become significantly less of a factor in Ronaldo's performances.</p>

            <p>Messi also started his footballing career stuck to the by-line, eventually moving to a more central role as his primary position for most of his career. However, Messi plays a very different game to a traditional centre-forward, with his natural Number 10 abilities making him the perfect False 9. As a result Messi comes much deeper, towards the play, rather than making runs and playing off the shoulder of the defenders.</p>

            <p>Because he often comes deep to receive the ball, dribbling is still a major part of Messi's game, completing an incredible <strong className="text-messi">{mAllTimeDribbles} dribbles</strong> in the league and Champions League, compared to Ronaldo's <strong className="text-ronaldo">{rAllTimeDribbles} successful dribbles</strong> (since 2003/04).</p>

            <p>There is therefore little doubt that Lionel Messi is the best when it comes to dribbling.</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-messi py-1 px-2 border border-gray-700 border-l-0">Messi</strong>
              </p> */}

              <Link to={`/detailed-stats/successful-dribbles/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">More Dribbling Stats</Link>
            </div>
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who's the best at heading?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>Heading is without doubt the most clear-cut category, with Ronaldo's heading stats absolutely dwarfing Messi's.</p>

            <p>Over their entire careers, <strong className="text-ronaldo">Ronaldo has scored {rAllTimeHead} headers in {rAllTimeApps} appearances</strong>, whereas <strong className="text-messi">Messi has scored just {mAllTimeHead} headers in {mAllTimeApps} appearances</strong>.</p>

            <p>In addition to headed goals, <strong className="text-ronaldo">Ronaldo has also won {rAllTimeAerialDuels} aerial duels</strong> in the league and Champions League since 2009/10 compared to <strong className="text-messi">Messi's {mAllTimeAerialDuels} aerial duels won</strong>. </p>

            <p>Of course, when you look at their respective builds, the way they play, the way their teams play, these numbers are hardly surprising.</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-ronaldo py-1 px-2 border border-gray-700 border-l-0">Ronaldo</strong>
              </p> */}

              <Link to={`/detailed-stats/aerial-duels/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">More Aerial Duel Stats</Link>
            </div>
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who's the best at shooting?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>The stats show that Ronaldo consistently has more shots on goal than Messi, totalling <strong className="text-ronaldo">{rAllTimeShots} shots</strong> in the league and Champions League since 2009/10, compared to Messi's <strong className="text-messi">{mAllTimeShots} shots</strong>.</p>

            <p>However, <strong className="text-messi">Messi converts a higher percentage of his shots, scoring a goal with every {parseFloat(mAllTimeShotsPerGoal).toFixed(2)} shots</strong>, whereas <strong className="text-ronaldo">Ronaldo scores a goal with every {parseFloat(rAllTimeShotsPerGoal).toFixed(2)} shots</strong>.</p>

            <p>Leo Messi also gets more of his shots on target, with <strong className="text-messi">{percentageOf(mAllTimeShotsOnTarget,mAllTimeShots)}%</strong>, of his efforts on target compared to Ronaldo's <strong className="text-ronaldo">{percentageOf(rAllTimeShotsOnTarget,rAllTimeShots)}%</strong>,.</p>

            <p>However, despite Messi's superior efficiency, the sheer volume of attempts Cristiano Ronaldo is able to produce is incredible and an important skill in itself. He also has the edge in terms of shooting from long-range, as well as with his weak foot and head, so that's enough to call this one a tie.</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-highlight py-1 px-2 border border-gray-700 border-l-0">Tie</strong>
              </p> */}

              <Link to={`/detailed-stats/shot-conversion/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">More Shooting Stats</Link>
            </div>
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who's the best at penalties?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>When it comes to penalties, Ronaldo's numbers are comfortably superior to Messi's, although perhaps not by as much as some people think. The media perception is that Messi is unreliable from the spot, whereas Ronaldo is the ultimate ice-cool penalty king - the truth, is somewhere in between.</p>

            <p>Excluding shootouts, <strong className="text-ronaldo">Ronaldo has scored an impressive {rAllTimePens} penalties (with {rAllTimePensMissed} misses)</strong> over his whole career, whereas <strong className="text-messi">Messi has scored {mAllTimePens} penalties (with {mAllTimePensMissed} misses)</strong>. </p>

            <p>This gives <strong className="text-ronaldo">Ronaldo an overall penalty conversion rate of {Math.round(parseFloat(rAllTimePens) * (100 / (parseFloat(rAllTimePens) + parseFloat(rAllTimePensMissed))))}%</strong>, compared to <strong className="text-messi">Messi's overall penalty conversion rate of {Math.round(parseFloat(mAllTimePens) * (100 / (parseFloat(mAllTimePens) + parseFloat(mAllTimePensMissed))))}%</strong>.</p>

            <p>Ronaldo's stats are clearly better, however he's not <i>as</i> reliable as he is sometimes portrayed, with other elite strikers such as Lewandowski and Ibrahimovic boasting significantly better conversion rates.</p>

            <p>In terms of big penalty misses, Messi missed one in the 2016 Copa America final shootout against Chile, and the 2012 Champions League semi-final miss against Chelsea.</p>
            
            <p>For Ronaldo, his biggest penalty misses came in the 2008 Champions League final shootout against Chelsea (his Man Utd side would ultimately go on to win due to John Terry's miss), and in the 2012 Champions League semi-final shootout against Bayern Munich (which did prove fatal for Real Madrid).</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-ronaldo py-1 px-2 border border-gray-700 border-l-0">Ronaldo</strong>
              </p> */}

              <Link to={`/detailed-stats/penalties/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">More Penalty Stats</Link>
            </div>
          </div>
        </div>
      </CopyBlock>
      
      <CopyBlock type="faq">
        <h2 itemProp="name">Who's the best at free kicks?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>In recent years, Messi has become the ultimate free kick specialist, whilst Ronaldo's proficiency in this area has diminished somewhat alarmingly. However, to say that this category is therefore cut-and-dry is simple recency bias.</p>

            <p>For example, it may be true that from <strong className="text-highlight">2017-2019</strong>, Messi scored <strong className="text-messi">23</strong> free kick goals to Ronaldo's <strong className="text-ronaldo">5</strong>; however, from <strong className="text-highlight">2009-2011</strong>, Ronaldo scored <strong className="text-ronaldo">21</strong> direct free kick goals to Messi's <strong className="text-messi">3</strong>.</p>

            <p>In terms of the <strong className="text-highlight">all time career</strong> stats, <strong className="text-messi">Messi has now scored {mAllTimeFreeKicks} free kick goals</strong> compared to <strong className="text-ronaldo">Ronaldo's {rAllTimeFreeKicks} free kick goals</strong>.</p>

            <p>A few years ago this category seemed like a lock for Cristiano Ronaldo, but Leo Messi's stunning turnaround in recent years means he's caught up with Ronaldo and also has the better <Link to={`/detailed-stats/free-kicks/`} className="text-highlight underline">free kick conversion</Link> rate, so he just shades this one.</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-messi py-1 px-2 border border-gray-700 border-l-0">Messi</strong>
              </p> */}

              <Link to={`/detailed-stats/free-kicks/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">More Free Kick Stats</Link>
            </div>
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who scores more hat tricks?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>The rate at which Messi and Ronaldo score hat tricks is scarcely believable, with a staggering {Math.round(mAllTimeHatTricks)+Math.round(rAllTimeHatTricks)} between them; <strong className="text-messi">{mAllTimeHatTricks} career hat tricks for Messi</strong> and <strong className="text-ronaldo">{rAllTimeHatTricks} career hat tricks for Ronaldo</strong>.</p>

            <p>Ronaldo may still have the overall lead, but Messi has a slight edge in terms of <strong className="text-highlight">frequency</strong>. <strong className="text-messi">Messi scores a hat trick every {gamesPerStat(mAllTimeHatTricks, mAllTimeApps)} games</strong>, whereas <strong className="text-ronaldo">Ronaldo scores a hat trick every {gamesPerStat(rAllTimeHatTricks, rAllTimeApps)} games</strong>.</p>

            <p>These numbers are so tight, and to add to this, their hat trick count in <strong className="text-highlight">league</strong> games is: <strong className="text-messi">{mLeagueHatTricks} hat tricks for Messi</strong> and <strong className="text-ronaldo">{rLeagueHatTricks} hat-tricks for Ronaldo</strong>. And in the <strong className="text-highlight">Champions League</strong>? <strong className="text-messi">{mChampsHatTricks} hat tricks for Messi</strong> and <strong className="text-ronaldo">{rChampsHatTricks} hat tricks for Ronaldo</strong>.</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-highlight py-1 px-2 border border-gray-700 border-l-0">Tie</strong>
              </p> */}

              <Link to={`/detailed-stats/hat-tricks/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">More Hat-trick Stats</Link>
            </div>
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who has won more individual awards?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>No other footballers in history have sustained such elite levels for such a long period of time. Their stranglehold on the coveted <strong className="text-highlight">Ballon d'Or</strong> award has been relentless, with <strong className="text-messi">Messi's 8 Ballon d'Or</strong> trophies edging <strong className="text-ronaldo">Ronaldo's haul of 5 Ballons d'Or</strong>. No other player in history has won more than 3! If Messi or Ronaldo didn't have each other to compete with for the award over the years, it's not inconceivable that one of them could have picked up the award 10 times or more.</p>

            <p>In terms of UEFA's top award, it's Ronaldo that has the edge with 4 awards (1x UEFA Club Footballer of the Year, 2x UEFA Best Player in Europe, 1x UEFA Men's Player of the Year) to Messi's 3 awards (1x UEFA Club Footballer of the Year, 2x UEFA Best Player in Europe).</p>

            <p>In terms of golden boots, Messi has finished as the league's top scorer <strong className="text-messi">8 times</strong>, winning the <strong className="text-highlight">European Golden Shoe</strong> on 6 of those occasions. Ronaldo has finished as top scorer in the league <strong className="text-ronaldo">5 times</strong> (once in the Premier League, once in Serie A, and 3 times in La Liga), winning 4 European Golden Shoes in the process.</p>

            <p>Ronaldo has won the <strong className="text-ronaldo">Puskas award</strong> for best goal in a calendar year (2 nominations), whereas Messi has so far failed to win this award despite being nominated 7 times.</p>

            <p>Messi has however won the <strong className="text-messi">Golden Ball</strong>, awarded to the player of the tournament at the World Cup, on two occasions - once in 2014 when he took his side to the final and again in 2022 where he was triumphant. He has also been named Copa America's Best Player on two occasions, and Best Young Player once.</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-messi py-1 px-2 border border-gray-700 border-l-0">Messi</strong>
              </p> */}

              <Link to={`/honours-and-achievements/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">All Honours & Achievements</Link>
            </div>
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
        <h2 itemProp="name">Who has won more trophies?</h2>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            <p>In terms of <strong className="text-highlight">trophies</strong>, Messi has the overall lead, with <strong className="text-messi">45 trophies</strong> to Ronaldo's <strong className="text-ronaldo">35 trophies</strong> (including Messi's 2008 Olympic triumph and 2005 U-20 World Cup).</p>

            {/* <p>In terms of frequency, <strong className="text-messi">Messi has won 44% of the senior competitions</strong> he has played, and <strong className="text-ronaldo">Ronaldo has won 39% of senior competitions</strong> played.</p> */}

            <p>Internationally, Messi has now won both the Copa America (x2) and the World Cup with Argentina, while Ronaldo has won the Euros with Portugal as well as a Nations League trophy.</p>

            <p>Messi has 12 league titles to Ronaldo's 7, but Ronaldo has the ascendancy in terms of Champions League triumphs with 5 to Messi's 4. There's not a lot in it, but the overall figures means Messi currently edges this one.</p>

            <div className="flex justify-between pt-6">
              {/* <p className="pt-2">
                <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                <strong className="uppercase text-messi py-1 px-2 border border-gray-700 border-l-0">Messi</strong>
              </p> */}

              <Link to={`/honours-and-achievements/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">All Honours & Achievements</Link>
            </div>
          </div>
        </div>
      </CopyBlock>

      <CopyBlock type="faq">
          <h2 itemProp="name">Who has more records?</h2>
          <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
            <div itemProp="text">
              <p>In short, Messi and Ronaldo have a similar number of records to their name. The most startling is maybe Messi's <strong className="text-highlight">Guiness World Record</strong> for scoring <strong className="text-messi">91 goals in a calendar year</strong> in <Link to={`/calendar-year-stats/2012/`} className="text-highlight underline">2012</Link>. To put this into context, Messi's next best is 60 and Ronaldo's best is 69. To score 91 goals in a year is other worldly.</p>

              <p><strong className="text-ronaldo">Ronaldo however dominates the Champions League record books</strong>, with most goals, most assists, most free kick goals, most hat-tricks (shared with Messi), and most goals in a single season.</p>

              <p><strong className="text-messi">Messi holds many European records at domestic level</strong>, with the most league goals scored in a single season (50 goals), and most consecutive league matches scored in in world football (21 matches, 33 goals).</p>

              <p>At International level, <strong className="text-ronaldo">Ronaldo is the all time top scorer with an unbelievable {rIntGoals} goals</strong>, whilst Messi is the highest scoring South American of all time with <strong className="text-messi">{mIntGoals} goals</strong>.</p>

              <div className="flex justify-between pt-6">
                {/* <p className="pt-2">
                  <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                  <strong className="uppercase text-highlight py-1 px-2 border border-gray-700 border-l-0">Tie</strong>
                </p> */}

                <Link to={`/records/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">All Records</Link>
              </div>
            </div>
          </div>
      </CopyBlock>

      <CopyBlock type="faq">
          <h2 itemProp="name">Who's better at international level?</h2>
          <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
            <div itemProp="text">
              <p>Both players can claim to have enjoyed fantastic careers at international level - their numbers aren't quite as other-wordly as what they produce for their club sides, but they are still extremely impressive nonetheless.</p>

              <p><strong className="text-ronaldo">Ronaldo scores a goal every {Math.round(rIntMinsPerGoal)} minutes</strong> for Portugal, and <strong className="text-messi">Messi scores a goal every {Math.round(mIntMinsPerGoal)} minutes</strong> for Argentina. When you factor in assists, <strong className="text-ronaldo">Ronaldo makes a goal contribution every {Math.round(rIntMinsPerGoalCont)} minutes</strong>, whereas <strong className="text-messi">Messi makes a goal contribution every {Math.round(mIntMinsPerGoalCont)} minutes</strong>.</p>

              <p>Ronaldo is Portugal's all-time top scorer with <strong className="text-ronaldo">{rIntGoals} goals</strong> (in {rIntApps} appearances), and Messi is Argentina's all-time top scorer with <strong className="text-messi">{mIntGoals} goals</strong> (in {mIntApps} appearances).</p>

              <p>In terms of individual accolades for the national team, Ronaldo won the <strong className="text-ronaldo">Euro 2021 Golden Boot</strong> for the top scorer, as well as the <strong className="text-ronaldo">Silver Boot at Euro 2016</strong> for scoring the 2nd most goals in the tournament. Messi has won the <strong className="text-messi">World Cup Golden Ball twice (2014 + 2022)</strong> (the only player ever to do so) and the <strong className="text-messi">Copa America Golden Ball twice (2015 & 2021)</strong>, awarded to the best player of those respective tournaments. He also scooped the <strong className="text-messi">Golden Boot award at Copa America 2021</strong> and the <strong className="text-messi">Silver Boot award at the 2022 World Cup</strong>.</p>

              <p>Messi has experienced more heartache at international level, losing in 4 major finals, but he was finally able to triumph with his country at full senior level at Copa America 2021. He went on to win the Finalissima trophy (UEFA / CONMEBOL Cup of Champions) in 2022 against European champions Italy, and then won the biggest prize of them all later that year: the World Cup!</p>

              <p>In 2024 Messi added a 2nd Copa America trophy, making his Argentina team just the 2nd team in men's internationall football history to win 3 consecutive major tournaments with a World Cup included.</p>
              
              <p>This is in addition to his Olympic Gold medal (with an Argentina U23 side) and the FIFA U20 World Cup trophy.</p>

              <p>Ronaldo has reached 2 major international finals with Portugal, winning one of these at the 2016 European Championships, and losing the other at Euro 2004. He also won the UEFA Nations League with Portugal, and has the most goals in the history of international football!</p>
              
              <p>Ronaldo has the better goalscoring record, but Messi has more individual accolades (4 Best Player awards at major tournaments - the most in history), and has now won both the Copa America and World Cup!</p>

              <div className="flex justify-between pt-6">
                {/* <p className="pt-2">
                  <span className="uppercase py-1 px-2 text-noir tracking-wide bg-highlight">Winner:</span>
                  <strong className="uppercase text-messi py-1 px-2 border border-gray-700 border-l-0">Messi</strong>
                </p> */}

                <Link to={`/international-stats/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">More International Stats</Link>
              </div>
            </div>
          </div>
      </CopyBlock>

      <CopyBlock id="vote">
        <h2>Cast your vote!</h2>
        <Link to={`/vote-and-discuss/`} className="inline-block bg-highlight text-noir text-lg font-semibold uppercase px-4 py-2 rounded-full">Vote Now!</Link>
      </CopyBlock>

      <CopyBlock>
        <h2>Discussion</h2>
        <Link to={`/vote-and-discuss/`} className="inline-block bg-highlight text-noir text-sm px-4 py-2 rounded-full">Debate and Discuss</Link>
      </CopyBlock>

      <LastUpdated type="All Time Stats" />
      
    </Layout>
  )
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/home/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          xg
          xa
          fkc
          xgc
          xac
          bigChancesCreated
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/home/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          xg
          xa
          fkc
          xgc
          xac
          bigChancesCreated
        }
      }
    }
  }
`

export default IndexPage
